/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import "./home.scss";
import Slider from 'react-slick';

export const Home = () => {
    let imageData = [
        "/assets/images/kailasa-bg-1.jpg",
        "/assets/images/kailasa-bg-2.jpg",
        "/assets/images/kailasa-bg-3.jpg"
    ];

    useEffect(() => {
        imageData.forEach((src) => {
            const img = new Image();
            img.src = src;
        });
    }, [imageData]);

    return (
        <section id="home" className='home-section'>
            <Slider
                dots={false}
                fade={true}
                lazyLoad='ondemand'
                autoplay={true}
                infinite={true}
                speed={1500}
                adaptiveHeight={true}
                slidesToShow={1}
                slidesToScroll={1}
            >
                {imageData.map((image, index) => (
                    <div key={index}>
                        <img
                        rel='preload'
                            className='h-100 w-100'
                            src={image}
                            alt={`Slide ${index + 1}`}
                            loading="lazy"
                        />
                    </div>
                ))}
            </Slider>
        </section>
    );
};
